<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="false"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width: settings.leftMenuWidth + 'px',
        height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
      }"
    >
<!--      <vue-custom-scrollbar-->
<!--        class="scroll-area"-->
<!--        :style="{-->
<!--          height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 110px)',-->
<!--        }"-->
<!--        :settings="scrollbarSettings"-->
<!--        @ps-scroll-y="scrollHandle"-->
<!--      >-->
        <a-menu
          forceSubMenuRender
          :inlineCollapsed="settings.isMobileView ? false : settings.isMenuCollapsed"
          mode="inline"
          :selectedKeys="selectedKeys"
          :openKeys.sync="openKeys"
          @click="handleClick"
          @openChange="handleOpenChange"
          :inlineIndent="15"
          :class="$style.navigation"
          theme="light"
          style="overflow-y: auto; overflow-x: hidden"
          :style="{
            height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 68px)' }"
        >
          <template v-for="(item, index) in menuData">
            <template v-if="((item.roles && userHasAnyRole(user, item.roles)) || (userCanView(user, item.key))) && item.show">
              <template v-if="item.title !== 'Pages' || shouldRenderPages">
                <a-menu-item-group :key="index" v-if="item.category">
                  <template slot="title">{{ item.title }}</template>
                </a-menu-item-group>
                <item
                  v-if="!item.children && !item.category"
                  :menu-info="item"
                  :styles="$style"
                  :key="item.key"
                />
                <sub-menu v-if="item.children" :menu-info="item" :styles="$style" :key="item.key" />
            </template>
            </template>
          </template>
        </a-menu>
<!--        <div :class="$style.banner">-->
<!--          <p>More components, more style, more themes, and premium support!</p>-->
<!--          <a-->
<!--            href="https://themeforest.net/item/clean-ui-react-admin-template/21938700"-->
<!--            target="_blank"-->
<!--            rel="noopener noreferrer"-->
<!--            class="btn btn-sm btn-success btn-rounded px-3"-->
<!--          >Buy Bundle</a>-->
<!--        </div>-->
<!--      </vue-custom-scrollbar>-->
    </div>
  </a-layout-sider>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
// import vueCustomScrollbar from 'vue-custom-scrollbar'
import { getMenuData } from '@/services/menu' // load menu data from services/menu/index.js
import SubMenu from './partials/submenu'
import Item from './partials/item'
import accessMix from '@/services/accessmix.js'

export default {
  name: 'menu-left',
  components: { SubMenu, Item },
  mixins: [accessMix],
  data() {
    return {
      menuData: getMenuData,
      selectedKeys: [],
      openKeys: [],
      scrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
      },
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    '$route'() {
      this.setSelectedKeys()
    },
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
    shouldRenderPages() {
      return process.env.VUE_APP_API_URL === 'https://api.olimp.work/api'
    },
  },
  methods: {
    scrollHandle(evt) {
      console.log(evt)
    },
    menuBannersOnly() {
      this.menuData.forEach((e) => { e.show = false })
      const banIndex = this.menuData.findIndex(obj => obj.key === 'banners')
      this.menuData[banIndex].show = true
    },
    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed
      this.$store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    },
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
        return
      }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()

      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])

      this.selectedKeys = selectedItem ? [selectedItem.key] : []

      // Проверка на вложенные маршруты
      if (!this.selectedKeys?.length) {
        const nestedPage = menuData.find(el => pathname.includes(el.url))
        if (nestedPage) {
          this.selectedKeys = [nestedPage.key]
        }
      }
    },
  },
  created () {
    this.menuData.forEach((e) => { e.show = true })
    if (this.settings.bannersOnly) { this.menuBannersOnly() }
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.setSelectedKeys()
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 256px;
  /*height: 400px;*/
  /*z-index: 99999;*/
  /*height: 400px;*/
}
</style>
