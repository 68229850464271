import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
// import store from '@/store'

Vue.use(Router)

const router = new Router({
  // base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/promo-images/promo-list', // 'dashboard/alpha',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
        breadCrumb: 'Home',
      },
      breadcrumbName: 'Home',
      children: [
        {
          path: '/database',
          meta: {
            title: 'Database',
            crumbName: 'Database',
          },
          component: () => import('./views/apps/database'),
        },
        {
          path: '/main',
          meta: {
            title: 'Main',
          },
          breadcrumbName: 'Main',
          component: () => import('./views/apps/viewscreens'),
          children: [
            {
              name: 'main',
              path: '/main/:activeTabId?',
              meta: {
                title: 'Main',
                breadCrumb: 'Main',
              },
              breadcrumbName: 'Main',
              component: () => import('./views/apps/viewscreens/viewScreensMain'),
            },
            {
              name: 'viewscreenForm',
              path: '/main/viewscreen-form/create-viewscreen',
              meta: {
                title: 'New Viewscreen',
              },
              breadcrumbName: 'New viewscreen',
              component: () => import('./views/apps/viewscreens/viewscreenForm'),
            },
            {
              name: 'editViewscreen',
              path: '/main/viewscreen-form/edit-viewscreen/:id',
              meta: {
                title: 'Edit Viewscreen',
              },
              breadcrumbName: 'Edit viewscreen',
              component: () => import('./views/apps/viewscreens/viewscreenForm'),
            },
          ],
        },
        {
          path: '/segments',
          meta: {
            title: 'Segments',
          },
          breadcrumbName: 'segments',
          component: () => import('./views/apps/segments'),
          children: [
            {
              name: 'segments',
              path: '/',
              meta: {
                title: 'Segments',
                breadCrumb: 'segments',
              },
              breadcrumbName: 'segments',
              component: () => import('./views/apps/segments/segmentTable'),
            },
            {
              name: 'segmentItem',
              path: 'edit/:id',
              meta: {
                title: 'Edit Segment',
              },
              breadcrumbName: 'Edit segment',
              component: () => import('./views/apps/segments/segmentItem'),
            },
          ],
        },
        {
          path: '/informers',
          meta: {
            title: 'Informers',
          },
          breadcrumbName: 'Informers',
          component: () => import('./views/apps/informers'),
          children: [
            {
              name: 'informers',
              path: 'informers-list/:viewscreenId?/:sectionId?',
              meta: {
                title: 'Informers',
                breadCrumb: 'Informers',
              },
              breadcrumbName: 'Informers',
              component: () => import('./views/apps/informers/informersList'),
            },
            {
              name: 'createInformer',
              path: 'create-informer/:viewscreenId?/:sectionId?',
              meta: {
                title: 'Create informer',
              },
              breadcrumbName: 'Create informer',
              component: () => import('./views/apps/informers/informersForm'),
            },
            {
              name: 'editInformer',
              path: 'edit-informer/:id/:viewscreenId?/:sectionId?',
              meta: {
                title: 'Edit informer',
              },
              breadcrumbName: 'Edit informer',
              component: () => import('./views/apps/informers/informersForm'),
            },
          ],
        },
        {
          path: '/promo-images',
          meta: {
            title: 'Promo images',
          },
          breadcrumbName: 'Promo images',
          component: () => import('./views/apps/promo-images'),
          children: [
            {
              name: 'promo-images',
              path: 'promo-list/:viewscreenId?/:sectionId?',
              meta: {
                title: 'Banners & Stories',
                breadCrumb: 'Promo images',
              },
              breadcrumbName: 'Promo images',
              component: () => import('./views/apps/promo-images/promoList'),
            },
            {
              name: 'promoPage',
              path: 'create-promo/:viewscreenId?/:sectionId?',
              meta: {
                title: 'Promo image',
              },
              breadcrumbName: 'Create promo image',
              component: () => import('./views/apps/promo-images/promoForm'),
            },
            {
              name: 'editPromo',
              path: 'edit-promo/:type/:id/:viewscreenId?',
              meta: {
                title: 'Promo image',
              },
              breadcrumbName: 'Edit promo image',
              component: () => import('./views/apps/promo-images/promoForm'),
            },
          ],
        },
        {
          path: '/popup-banners',
          meta: {
            title: 'Pop-up banners',
          },
          breadcrumbName: 'Pop-up banners',
          component: () => import('./views/apps/popup-banners'),
          children: [
            {
              name: 'create-pop-up',
              path: 'create',
              meta: {
                title: 'Create pop-up banner',
              },
              breadcrumbName: 'Create pop-up banner',
              component: () => import('./views/apps/popup-banners/form'),
            },
            {
              name: 'edit-pop-up',
              path: 'edit/:id',
              meta: {
                title: 'Edit pop-up banner',
              },
              breadcrumbName: 'Edit pop-up banner',
              component: () => import('./views/apps/popup-banners/form'),
            },
            {
              name: 'popup-banners',
              path: '/',
              meta: {
                title: 'Pop-up banners',
                breadCrumb: 'Pop-up banners',
              },
              breadcrumbName: 'Pop-up banners',
              component: () => import('./views/apps/popup-banners/list'),
            },
          ],
        },
        {
          path: '/log/models',
          meta: {
            title: 'Model change logs',
            breadCrumb: 'model logs',
          },
          breadcrumbName: 'Model change logs',
          component: () => import('./views/apps/logging/models'),
        },
        {
          path: '/splashscreens',
          meta: {
            title: 'Splashscreens',
          },
          breadcrumbName: 'Splashscreens',
          component: () => import('./views/apps/splashscreens'),
        },
        // {
        //   path: '/main',
        //   meta: {
        //     title: 'Main',
        //   },
        //   breadcrumbName: 'Main',
        //   component: () => import('./views/apps/main'),
        // },
        {
          path: '/top-matches',
          meta: {
            title: 'Top matches',
          },
          breadcrumbName: 'Top matches',
          component: () => import('./views/apps/top'),
        },
        {
          path: '/fortune-wheel',
          meta: {
            title: 'Fortune wheel',
          },
          breadcrumbName: 'Fortune wheel',
          component: () => import('./views/apps/fortune-wheel'),
          children: [
            {
              name: 'fortune-wheel',
              path: '/',
              meta: {
                title: 'Fortune wheel',
                breadCrumb: 'Fortune wheel',
              },
              breadcrumbName: 'Fortune wheel',
              component: () => import('./views/apps/fortune-wheel/list'),
            },
            {
              name: 'create-fortune-wheel',
              path: 'create',
              meta: {
                title: 'Create fortune wheel',
              },
              breadcrumbName: 'Create fortune wheel',
              component: () => import('./views/apps/fortune-wheel/form'),
            },
            {
              name: 'current-fortune-wheel',
              path: ':id',
              meta: {
                title: 'Fortune wheel',
              },
              breadcrumbName: 'Fortune wheel',
              component: () => import('./views/apps/fortune-wheel/wheel'),
            },
            {
              name: 'edit-current-fortune-wheel',
              path: ':id/edit',
              meta: {
                title: 'Edit fortune wheel',
              },
              breadcrumbName: 'Edit fortune wheel',
              component: () => import('./views/apps/fortune-wheel/form'),
            },
            {
              name: 'view-current-fortune-wheel',
              path: ':id/view',
              meta: {
                title: 'View fortune wheel',
              },
              breadcrumbName: 'View fortune wheel',
              component: () => import('./views/apps/fortune-wheel/form'),
            },
            {
              name: 'create-sector-for-fortune-wheel',
              path: ':id/create-sector',
              meta: {
                title: 'Create sector for fortune wheel',
              },
              breadcrumbName: 'Create sector',
              component: () => import('./views/apps/fortune-wheel/sector-form'),
            },
            {
              name: 'edit-sector-for-fortune-wheel',
              path: ':id/edit-sector/:sectorId',
              meta: {
                title: 'Edit sector for fortune wheel',
              },
              breadcrumbName: 'Edit sector',
              component: () => import('./views/apps/fortune-wheel/sector-form'),
            },
            {
              name: 'view-sector-for-fortune-wheel',
              path: ':id/view-sector/:sectorId',
              meta: {
                title: 'View sector for fortune wheel',
              },
              breadcrumbName: 'View sector',
              component: () => import('./views/apps/fortune-wheel/sector-form'),
            },
          ],
        },
        {
          path: '/events',
          meta: {
            title: 'events',
            breadCrumb: 'Events',
          },
          breadcrumbName: 'events',
          component: () => import('./views/apps/events'),
          children: [
            {
              name: 'events',
              path: '/',
              meta: {
                title: 'events',
                breadCrumb: 'Events',
              },
              breadcrumbName: 'events',
              component: () => import('./views/apps/events/events'),
            },
            {
              name: 'event',
              path: ':event',
              props: true,
              meta: {
                title: 'event',
                breadCrumb: 'event',
              },
              breadcrumbName: 'event',
              component: () => import('./views/apps/events/event'),
            },
            {
              name: 'page',
              path: ':event/page-:page',
              props: true,
              meta: {
                title: 'page',
                breadCrumb: 'Event',
              },
              breadcrumbName: 'Page',
              component: () => import('./views/apps/events/page'),
            },
          ],
        },
        {
          path: '/affiliates',
          meta: {
            title: 'affiliates',
            breadCrumb: 'Affilates',
          },
          breadcrumbName: 'affiliates',
          component: () => import('./views/apps/affiliates'),
          children: [
            {
              path: '/',
              meta: {
                title: 'affilates',
                breadCrumb: 'Affiliates',
              },
              breadcrumbName: 'affiliates',
              component: () => import('./views/apps/affiliates/codes'),
            },
            {
              name: 'users',
              path: ':code',
              props: true,
              meta: {
                title: 'users',
                breadCrumb: 'users',
              },
              breadcrumbName: 'users',
              component: () => import('./views/apps/affiliates/users'),
            },
            // {
            //   name: 'page',
            //   path: ':event/page-:page',
            //   props: true,
            //   meta: {
            //     title: 'page',
            //     breadCrumb: 'Event',
            //   },
            //   breadcrumbName: 'Page',
            //   component: () => import('./views/apps/events/page'),
            // },
          ],
        },
        {
          path: '/prisma',
          meta: {
            title: 'Prisma',
          },
          breadcrumbName: 'Prisma',
          component: () => import('./views/apps/prisma'),
          children: [
            {
              name: 'Prisma users',
              path: '/',
              meta: {
                title: 'Prisma users',
                breadCrumb: 'Prisma users',
              },
              breadcrumbName: 'Prisma users',
              component: () => import('./views/apps/prisma'),
            },
            {
              name: 'User transactions',
              path: ':userLogin/:uuid/transactions',
              meta: {
                title: 'User transactions',
                breadCrumb: 'User transactions',
              },
              breadcrumbName: 'User transactions',
              component: () => import('./views/apps/prisma'),
            },
            {
              name: 'User bets',
              path: ':userLogin/:uuid/bets',
              meta: {
                title: 'User bets',
                breadCrumb: 'User bets',
              },
              breadcrumbName: 'User bets',
              component: () => import('./views/apps/prisma'),
            },
            {
              name: 'User prizes',
              path: ':userLogin/prizes',
              meta: {
                title: 'User prizes',
                breadCrumb: 'User prizes',
              },
              breadcrumbName: 'User prizes',
              component: () => import('./views/apps/prisma'),
            },
            {
              name: 'Prize logs',
              path: ':userLogin/:prizeId/prize-logs',
              meta: {
                title: 'Prize logs',
                breadCrumb: 'Prize logs',
              },
              breadcrumbName: 'Prize logs',
              component: () => import('./views/apps/prisma'),
            },
            {
              name: 'User tasks',
              path: ':userLogin/:uuid/tasks',
              meta: {
                title: 'User tasks',
                breadCrumb: 'User tasks',
              },
              breadcrumbName: 'User tasks',
              component: () => import('./views/apps/prisma'),
            },
            {
              name: 'Task details',
              path: ':userLogin/:uuid/:userTaskId/:taskId/task-details',
              meta: {
                title: 'Task details',
                breadCrumb: 'Task details',
              },
              breadcrumbName: 'Task details',
              component: () => import('./views/apps/prisma'),
            },
          ],
        },
        {
          path: '/push-notifications',
          meta: {
            title: 'Push-notifications',
          },
          component: () => import('./views/apps/push-notifications'),
        },
        {
          path: '/push-notifications/add-push',
          meta: {
            title: 'Add Push-notification',
          },
          component: () => import('./views/apps/push-notifications/addPush.vue'),
        },
        {
          path: '/accounts',
          meta: {
            title: 'Accounts',
          },
          component: () => import('./views/apps/users'),
        },
        // {
        //   path: '/users',
        //   meta: {
        //     title: 'Users',
        //   },
        //   component: () => import('./views/apps/accounts'),
        // },
        //
        {
          path: '/profiles',
          meta: {
            title: 'Profiles',
            crumbName: 'Profiles',
          },
          component: () => import('./views/apps/profiles'),
          children: [
            {
              path: '/',
              redirect: 'users',
            },
            {
              path: 'users',
              meta: {
                title: 'Users',
              },
              component: () => import('./views/apps/profiles/users'),
            },
            {
              path: 'whitelist',
              meta: {
                title: 'Whitelist',
              },
              component: () => import('./views/apps/profiles/whitelist'),
            },
          ],
        },
        //
        {
          path: '/refferal_promocodes',
          meta: {
            title: 'Refferal promocodes',
          },
          component: () => import('./views/apps/refferal_promocodes'),
        },
        {
          path: '/analytics',
          meta: {
            title: 'Analytics',
          },
          component: () => import('./views/apps/analytics'),
        },
        {
          path: '/updates',
          meta: {
            title: 'Updates',
            crumbName: 'Updates',
          },
          component: () => import('./views/apps/updates'),
        },
        {
          path: '/settings',
          meta: {
            title: 'Settings',
            breadCrumb: 'Settings',
          },
          component: () => import('./views/settings'),
          children: [
            {
              path: '/',
              redirect: 'apps',
            },
            {
              path: 'apps',
              meta: {
                title: 'Apps',
              },
              component: () => import('./views/settings/components/apps.vue'),
            },
            {
              path: 'deeplinks',
              meta: {
                title: 'Deeplinks',
              },
              component: () => import('./views/settings/components/deeplinks/index.vue'),
              children: [
                {
                  name: 'deeplinks list',
                  path: '/',
                  meta: {
                    title: 'Deeplinks list',
                    breadCrumb: 'Deeplinks list',
                  },
                  breadcrumbName: 'Deeplinks list',
                  component: () => import('./views/settings/components/deeplinks/list.vue'),
                },
                {
                  name: 'create-deeplink',
                  path: 'create',
                  meta: {
                    title: 'Create deeplink',
                  },
                  breadcrumbName: 'Create deeplink',
                  component: () => import('./views/settings/components/deeplinks/form.vue'),
                },
                {
                  name: 'edit-deeplink',
                  path: ':id/edit',
                  meta: {
                    title: 'Edit deeplink',
                  },
                  breadcrumbName: 'Edit deeplink',
                  component: () => import('./views/settings/components/deeplinks/form.vue'),
                },
                {
                  name: 'view-deeplink',
                  path: ':id/view',
                  meta: {
                    title: 'View deeplink',
                  },
                  breadcrumbName: 'View deeplink',
                  component: () => import('./views/settings/components/deeplinks/form.vue'),
                },
              ],
            },
            {
              path: 'firebase-keys',
              meta: {
                title: 'firebase-keys',
              },
              component: () => import('./views/settings/components/firebase-keys.vue'),
            },
            {
              path: 'languages',
              meta: {
                title: 'Languages',
              },
              component: () => import('./views/settings/components/languages.vue'),
            },
          ],
        },
        {
          path: '/permissions',
          meta: {
            title: 'Roles & permissions',
          },
          component: () => import('./views/permissions'),
        },
        {
          path: '/cloud-store',
          meta: {
            title: 'Cloud store',
            crumbName: 'Cloud store',
          },
          component: () => import('./views/cloud-store'),
        },
        {
          path: '/subscriptions/matches',
          meta: {
            title: 'Match events subscriptions',
            crumbName: 'Match events subscriptions',
          },
          component: () => import('./views/apps/subscriptions/matches'),
        },
        {
          path: '/reviews',
          meta: {
            title: 'Reviews',
            crumbName: 'Reviews',
          },
          component: () => import('./views/feedbacks'),
        },
        {
          path: '/promocode',
          meta: {
            title: 'Promocode',
            crumbName: 'Promocode',
          },
          component: () => import('./views/apps/promocode'),
        },
        {
          path: '/sports',
          meta: {
            title: 'Icons & Colors',
            crumbName: 'Icons & Colors',
          },
          component: () => import('./views/apps/sports/colors'),
        },
        {
          path: '/gifs',
          meta: {
            title: 'Gifs',
            crumbName: 'Gifs',
          },
          component: () => import('./views/apps/gifs'),
        },
        {
          path: '/encryption',
          meta: {
            title: 'Encryption',
            crumbName: 'Encryption',
          },
          component: () => import('./views/apps/encryption'),
        },
        {
          path: '/backup',
          meta: {
            title: 'Backup',
            crumbName: 'Backup',
          },
          component: () => import('./views/apps/server/backups'),
        },
        {
          path: '/betatests',
          meta: {
            title: 'Betatests',
            crumbName: 'Betatests',
          },
          component: () => import('./views/betatests/users'),
        },
        {
          path: '/emergency-mode',
          meta: {
            title: 'Emergency mode',
            crumbName: 'Emergency mode',
          },
          component: () => import('./views/apps/emergency/index.vue'),
        },
        // {
        //   path: '/betatests',
        //   meta: {
        //     title: 'Betatests',
        //     crumbName: 'Betatests',
        //   },
        //   component: () => import('./views/betatests'),
        //   children: [
        //     {
        //       path: '/',
        //       redirect: 'beta',
        //     },
        //     {
        //       path: 'beta',
        //       meta: {
        //         title: 'Betatests',
        //       },
        //       component: () => import('./views/betatests/beta'),
        //     },
        //     {
        //       path: 'beta/:betatestId/comments',
        //       meta: {
        //         title: 'Betatest comments',
        //         crumbName: 'Betatest comments',
        //       },
        //       component: () => import('./views/betatests/beta/comments'),
        //     },
        //     {
        //       path: 'users',
        //       meta: {
        //         title: 'Betatests users',
        //       },
        //       component: () => import('./views/betatests/users'),
        //     },
        //   ],
        // },
      ],
    },
    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        // {
        //   path: '/auth/404',
        //   meta: {
        //     title: 'Error 404',
        //   },
        //   component: () => import('./views/auth/404'),
        // },
        // {
        //   path: '/auth/500',
        //   meta: {
        //     title: 'Error 500',
        //   },
        //   component: () => import('./views/auth/500'),
        // },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        // {
        //   path: '/auth/lockscreen',
        //   meta: {
        //     title: 'Lockscreen',
        //   },
        //   component: () => import('./views/auth/lockscreen'),
        // },
      ],
    },

    // Redirect to 404
    {
      path: '/:event/:page',
      component: () => import('./views/dinamic-page'),
    },
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    const token = localStorage.getItem('accessToken')
    if (!token) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
/* eslint-enable */

export default router
