<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <a
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        :class="$style.logo"
      >
        OLIMP ADMIN
        <span></span>
      </a>
      <br />
      <p class="mb-0">
        Copyright © 2020-{{ currentYear }} OlimpBet |
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >Privacy Policy</a>
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  computed: {
    currentYear() {
      return dayjs().format('YYYY')
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
